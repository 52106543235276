import React, {useState} from "react"
import {Row, Col, Container, Form, FormGroup, FormControl, FormLabel, Button} from "react-bootstrap"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFacebookF, faYoutube, faTwitter} from '@fortawesome/free-brands-svg-icons'
import { faEnvelope, faMapMarker } from "@fortawesome/free-solid-svg-icons";

import "../styles/style.scss"

import Layout from "../components/layout"
import SEO from "../components/seo"
// You can delete this file if you're not using it
const ContactPage = () => {
  const [validated, setValidated] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const handleSubmit = event => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
      setValidated(true);
      return;
    }

    event.preventDefault();
    event.stopPropagation();
    setSubmitted(true);
    // eslint-disable-next-line no-undef
    const data = new FormData(event.target);
    // eslint-disable-next-line no-undef
    fetch('https://data.accelerate.games/contact', {
      method: 'POST',
      body: data,
    });
  };

  let form;
  if (submitted) {
    form = (
      <div className={"form-confirmation"}>Thank you for your message.<br/>We will respond as soon as possible.</div>
    );
  } else  {
    form = (<Form noValidate validated={validated} onSubmit={handleSubmit}>
      <FormGroup>
        <label>Your Name</label>
        <FormControl required type="name" name="name" id="name"/>
        <Form.Control.Feedback type="invalid">
          Your Name cannot be empty.
        </Form.Control.Feedback>
      </FormGroup>
      <FormGroup>
        <FormLabel>Contact Email</FormLabel>
        <FormControl required type="email" name="email" id="email"/>
        <Form.Control.Feedback type="invalid">
          Please enter a valid email.
        </Form.Control.Feedback>
      </FormGroup>
      <FormGroup>
        <FormLabel>Message</FormLabel>
        <FormControl as="textarea" required name="message"  id="message" rows="3"
                     placeholder="Let us know how we can help."/>
        <Form.Control.Feedback type="invalid">
          Really let us know how we can help :)
        </Form.Control.Feedback>
      </FormGroup>
      <Button type="submit" variant="primary">Send</Button>
    </Form>);
  }
  return (
    <Layout pageInfo={{ pageName: "contact" }}>
      <SEO title="Contact" keywords={[]} />
      <Container className="contact">
        <Row>
          <Col lg={6}>
            <div>
              <h1>Contact</h1>
              <h6>
                <FontAwesomeIcon icon={faMapMarker} fixedWidth={true}/>&nbsp;Located in New York City, San Francisco and Los Angeles
              </h6>
              <h6>
                <a href="mailto:contact@accelerate.games" target="_blank" rel="noopener noreferrer">
                  <FontAwesomeIcon icon={faEnvelope} fixedWidth={true}/>&nbsp;contact@accelerate.games
                </a>
              </h6>
              <h6>
                <a href="https://www.facebook.com/AccelerateGames/" target="_blank" rel="noopener noreferrer">
                  <FontAwesomeIcon icon={faFacebookF} fixedWidth={true}/>&nbsp;Accelerate Games
                </a>
              </h6>
              <h6>
                <a href="https://twitter.com/gamesAccelerate" target="_blank" rel="noopener noreferrer">
                  <FontAwesomeIcon icon={faTwitter} className={'brandIcon'} fixedWidth={true}/>&nbsp;@GamesAccelerate
                </a>
              </h6>
              <h6>
                <a href="https://www.youtube.com/channel/UCSc9ABkRlpCH2s5N4_D-Nig" target="_blank" rel="noopener noreferrer">
                  <FontAwesomeIcon icon={faYoutube} className={'brandIcon'}  fixedWidth={true}/>&nbsp;Accelerate Games
                </a>
              </h6>
            </div>
          </Col>
          <Col lg={6} className="d-flex align-content-around flex-wrap">
            {form}
          </Col>
        </Row>
      </Container>
    </Layout>
  );
}

export default ContactPage;
